<template>
    <slot />

    <DxToast
        v-for="(item, index) in arrModelToast"
        :key="index"
        v-model:visible="item.isVisible"
        v-model:type="item.type"
        minWidth="100"
        width="auto"
        :displayTime="5000"
        contentTemplate="content"
        :position="{
            my: 'bottom center',
            at: 'bottom',
            of: window,
            offset: { x: 0, y: -(index * 32 + 32 * (index - 1)) },
        }"
    >
        <template #content>
            <div class="row align-center font-20 font-medium color-fff pr-2">
                <DxButton
                    :active-state-enabled="false"
                    :focus-state-enabled="false"
                    :hover-state-enabled="false"
                    styling-mode="contained"
                    :type="typeButton(item)"
                    :icon="'mdi mdi-' + typeIconButton(item)"
                    class="icon-toast"
                    width="48px"
                    height="48px"
                />
                {{ item.message }}
            </div>
        </template>
    </DxToast>
</template>

<script>
import { DxToast, DxButton } from "devextreme-vue";
import notify from "devextreme/ui/notify";
export default {
    components: {
        DxToast,
        DxButton,
    },
    data() {
        return {
            modelToast: {
                isVisible: false,
                message: "",
                type: "success",
            },
            arrModelToast: [
                {
                    isVisible: false,
                    message: "",
                    type: "error",
                },
            ],
        };
    },
    methods: {
        typeButton(item) {
            let type = item.type;

            let map = {
                success: "success",
                error: "error",
                warning: "warning",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
        typeIconButton(item) {
            let type = item.type;
            let map = {
                success: "check-circle-outline",
                error: "close-circle-outline",
                warning: "alert",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
        showToast(modelToast) {
            let self = this;
            notify(
                {
                    message: ``,
                    minWidth: "100",
                    width: "auto",
                    type: self.typeButton(modelToast),
                    contentTemplate(data) {
                        const result = document.createElement("div");
                        result.className =
                            "row align-center font-20 font-medium color-fff pr-2 color-" +
                            self.typeButton(modelToast);
                        const icon = document.createElement("i");
                        icon.className = `mr-1 mdi mdi-${self.typeIconButton(
                            modelToast
                        )}`;
                        result.append(icon);
                        result.append(`${modelToast.message}`);
                        return result;
                    },
                    displayTime: 10000,
                    animation: {
                        show: {
                            type: "fade",
                            duration: 400,
                            from: 0,
                            to: 1,
                        },
                        hide: { type: "fade", duration: 40, to: 0 },
                    },
                },
                { position: "bottom center", direction: "up-push" }
            );
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.showToast(params);
        });
    },
};
</script>

<style scoped>
</style>
